<template>
  <div class="forms">
    <v-toolbar flat>
      <v-toolbar-title>Formularios</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" rounded color="primary" :to="{name: 'forms-new'}">Nuevo</v-btn>
    </v-toolbar>

    <v-row class="mr-5">
      <v-spacer></v-spacer>
      <v-col justify="end" md="4">
        <v-text-field
          v-model="search"
          class="ml-5"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      flat
      :search="search"
      :headers="headers"
      :items="getFormsForms"
      :loading="getFormsIsLoading"
      height="536px"
      class="px-4"
    >
      <template v-slot:item.actions="{ item }">
        <v-row justify="end">
          <v-btn
            small
            rounded
            outlined
            color="primary"
            :to="{name: 'forms-edit', params: { formId: item.id }}"
          >Editar</v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { to } from '@/helpers'
import { FORMS_GET_FORMS } from '@/store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'Forms',
  props: {},
  mounted() {
    this.getForms()
  },
  data() {
    return {
      isLoading: true,
      search: '',
      error: null,
      Forms: [],
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Descripción', value: 'description' },
        { text: '', value: 'actions' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getFormsForms', 'getFormsIsLoading'])
  },
  methods: {
    async getForms() {
      const [err] = await to(this.$store.dispatch(FORMS_GET_FORMS))
      if (err) {
        this.error = err
        this.isLoading = false
      } else {
        this.Forms = this.getFormsForms
        this.isLoading = false
      }
    }
  },
  components: {}
}
</script>
